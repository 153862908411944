
import { Component, Vue } from "vue-property-decorator";

@Component<DefaultLayout>({
	middleware: ["check-redirect", "check-auth"],
})
export default class DefaultLayout extends Vue {
	isScrolled: boolean = false;

	mounted() {
		const listenerOptions: AddEventListenerOptions = {passive: true};
		window.addEventListener("resize", this.checkScroll, listenerOptions);
		window.addEventListener("scroll", this.checkScroll, listenerOptions);

		this.$nextTick(this.checkScroll);
	}

	destroyed() {
		window.removeEventListener("resize", this.checkScroll);
		window.removeEventListener("scroll", this.checkScroll);
	}

	checkScroll() {
		if (window.document && window.document.scrollingElement) {
			this.isScrolled = window.document.scrollingElement.scrollTop > 5;
		}
	}
}
